var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../assets/image/example/02-3banner.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("解决方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("方案收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"88rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"8rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explain"},[_c('div',{staticClass:"head-explain-title"},[_vm._v("DPM数字化工单")]),_c('div',{staticClass:"head-explain-content"},[_vm._v("实现工单科学管理，提升生产稳定性")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-title"},[_vm._v("设备信息离散")]),_c('div',{staticClass:"purchase-content"},[_vm._v("设备资料都以纸质形式或公共盘的形式保存，现场维护工作时，无法及时查看资料；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("问题反馈效率慢")]),_c('div',{staticClass:"purchase-content"},[_vm._v("问题反馈速率慢，导致人机、物料等待延时，造成损失；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("工作完成质量不易追踪")]),_c('div',{staticClass:"purchase-content"},[_vm._v("任务完成质量不易把控，设备运行容易出现故障，影响生产效率；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("流程自动化程度低")]),_c('div',{staticClass:"purchase-content"},[_vm._v("线下交叉沟通时间长，信息传递不及时；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("维护经验知识沉淀难")]),_c('div',{staticClass:"purchase-content"},[_vm._v("维修记录反馈均为纸质，无法形成维修经验知识库，不能及时生成各项指标及管理报表；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("管理信息断层")]),_c('div',{staticClass:"purchase-content"},[_vm._v("管理层无法直接了解到各个工厂的实际生产情况，工厂间绩效缺少比较与竞争；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("数据分析效率低")]),_c('div',{staticClass:"purchase-content"},[_vm._v("各项数据整合分析困难，维护情况和设备停机时间无法建立联系。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('img',{staticClass:"solution-img",attrs:{"src":require("../assets/image/example/02-3解决方案.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("方案收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('img',{staticClass:"benefits-img",attrs:{"src":require("../assets/image/example/02-3方案收益.svg")}})])
}]

export { render, staticRenderFns }